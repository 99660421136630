
import useMarketplaceChain from "hooks/useMarketplaceChain";
import {Box, Flex ,Text} from "../primitives";
import Hero_001 from  "../../public/index/2_LandHero_001.webp"
import Hero_002 from  "../../public/index/2_LandHero_002.webp"
import Hero_003 from  "../../public/index/2_LandHero_003.webp"
import Rarability from "../../public/index/4_Rarability_001.webp"
import Rarability1 from "../../public/index/4_Rarability_002.webp"
import Rarability2 from "../../public/index/4_Rarability_003.webp"
import Rarability3 from "../../public/index/4_Rarability_004.webp"
import Rarability4 from "../../public/index/4_Rarability_005.webp"
import Image from "next/image";
import Link from "next/link";
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useRouter } from "next/router";
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')

    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }
    return (
        <Box  >
            <Flex align="center" justify="center" direction="column">
                <Text style="body1" css={{
                    letterSpacing: ".15rem",
                    fontSize: "13px",
                    marginTop:"$6"
                }}>THROUGH THE LOOKING GLASS
                </Text>
                <Text style="h2" css={{
                    letterSpacing: ".15rem",
                    marginTop:"$6",
                    padding:"0 $4",
                    "@md": {
                        letterSpacing: ".15rem",
                        marginTop:"$6",
                    }
                }}>
                    A BOLD NEW REALM
                </Text>
                <Box css={{
                    maxWidth:"500px",
                    marginTop:"$4",
                    padding:"0 $4",
                    "@md":{
                        maxWidth:"500px",
                        marginTop:"$4",
                    }
                }}>
                    <Text style="body1">
                        We embarked on the creation of Beatworld with a single vision: the metaverse should be an exhilarating adventure. This world is vibrant, rich in stories, and every decision you make matters. Enter Beatworld, where the ordinary is left behind. Where you land is a roll of the dice. Where you venture next is entirely in your hands.
                    </Text>
                </Box>

            </Flex>

            <Flex justify="between" css={{
                marginTop:'$6'
            }}>
                <Box css={{
                    width:"32%",
                    borderRadius:"5px"
                }}>
                   <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}>

                       <Image src={Hero_001} alt="" style={{
                           borderRadius:"5px"
                       }}/>
                   </Link>
                </Box>
                <Box css={{
                    width:"32%",
                    borderRadius:"5px"
                }}>
                    <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}>
                    <Image src={Hero_002} alt="" style={{
                        borderRadius:"5px"
                    }}/>
                    </Link>
                </Box>
                <Box  css={{
                    width:"32%",
                    borderRadius:"5px"
                }}>
                    <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}>
                    <Image src={Hero_003} alt="" style={{
                        borderRadius:"5px"
                    }}/>
                    </Link>
                </Box>
            </Flex>
           <Box css={{
               animation:"scale-down 1.25s ease-out forwards",
               // background:"linear-gradient(308.02deg, rgba(42, 98, 240, .3) 21.14%, rgba(118, 37, 37, .3) 75.57%)",
               // filter:"blur(40px)",
               marginTop:'$4'
           }}>
             <Flex align="center" justify="center" css={{
                 marginTop:"$6"
             }}>
                 <Box css={{
                     maxWidth:"500px",
                     padding:'0 $4',
                     "@md": {
                         maxWidth:"500px",

                     }
                 }}>
                     <Text style="body1" >
                         Beatworld is a mix of galaxy and archipelago, with islands spreading from the Pulse Core at the center to the Endless Horizon. There are five land types: Pulse Core, Elemental Flux, Prism Sky, Ether Mirage, and Endless Horizon. Your Beatdeed unlocks a unique blend of environment, foundation, and treasures — some rich in resources, others hiding powerful relics. On a rare few, a Lumina roams.
                     </Text>
                 </Box>
             </Flex>
           </Box>
            <Flex align="center" justify="between" direction="column" css={{
                marginTop:"$6",
                "@md": {
                    marginTop:"$6",
                    alignItems:"center",
                    justifyContent:"space-between",
                    flexDirection:"row"
                }
            }}>
                <Box css={{
                    gap: 24,
                    width:"100%",
                    "@md":{
                        width:"19%",
                    }
                }}>
                    <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}>
                    <Flex align="center" direction="column" >
                        <Image src={Rarability} alt='' style={{
                            borderRadius:"5px"
                        }}/>
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            INFINITE EXPANSE
                        </Text>
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            SLUDGE - TIER 1
                        </Text>
                    </Flex>
                    </Link>
                </Box>
                <Box css={{
                    gap: 24,
                    width:"100%",
                    "@md":{
                        width:"19%",
                    }
                }}>
                    <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`} >
                    <Flex align="center" direction="column">
                    <Image src={Rarability1} alt='' style={{
                        borderRadius:"5px"
                    }}/>
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            COSMIC DREAM
                        </Text>
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            BOG - TIER 2
                        </Text>
                    </Flex>
                    </Link>
                </Box >
                <Box css={{
                    gap: 24,
                    width:"100%",
                    "@md":{
                        width:"19%",
                    }
                }}>
                    <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}>
                    <Flex align="center" direction="column">
                    <Image src={Rarability2} alt='' style={{
                        borderRadius:"5px"
                    }} />
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            RAINBOW ATMOS
                        </Text>
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            OBSIDIAN - TIER 3
                        </Text>
                    </Flex>
                    </Link>
                </Box>
                <Box css={{
                    gap: 24,
                    width:"100%",
                    "@md":{
                        width:"19%",
                    }
                }}>
                    <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}>
                    <Flex align="center" direction="column">
                    <Image src={Rarability3} alt='' style={{
                        borderRadius:"5px"
                    }}/>
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            CHEMICAL GOO
                        </Text>
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            LUSTER - TIER 4
                        </Text>
                    </Flex>
                    </Link>
                </Box>
                <Box css={{
                    gap: 24,
                    width:"100%",
                    "@md":{
                        width:"19%",
                    }
                }}>
                    <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}>
                    <Flex align="center" direction="column">
                    <Image src={Rarability4} alt='' style={{
                        borderRadius:"5px"
                    }} />
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            BIOGENIC SWAMP
                        </Text>
                        <Text style="body3" css={{
                            textAlign:"center",
                            paddingTop:"$3"
                        }}>
                            CRIMSON - TIER 5
                        </Text>
                    </Flex>
                    </Link>
                </Box>


            </Flex>
            <Box css={{
                background:"linear-gradient(90deg, #00ff38, #2056df 49.48%, #d74848 99.48%)",
                width:"100%",
                height:"2px",
                marginTop:'$6'
            }}>

            </Box>
        </Box>
    )
}

type TrendingCollectionsSchema =
    paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
    paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
    ssr: {
        trendingMints: TrendingMintsSchema
        trendingCollections: TrendingCollectionsSchema
        featuredCollections: TrendingCollectionsSchema
    }
}> = async ({ params, res }) => {
    const chainPrefix = params?.chain || ''
    const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
    // init BaseApi
    // supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
    // DefaultChain
    // console.log("请求的路劲",reservoirBaseUrl)
    const headers: RequestInit = {
        headers: {
            'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
    }
    let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'volume',
        }

    const trendingCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        trendingCollectionsQuery,
        headers
    )
    let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'sales',
        }

    const featuredCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        featuredCollectionQuery,
        headers
    )

    let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            type: 'any',
        }

    const trendingMintsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending-mints/v1`,
        trendingMintsQuery,
        headers
    )

    const promises = await Promise.allSettled([
        trendingCollectionsPromise,
        featuredCollectionsPromise,
        trendingMintsPromise,
    ]).catch((e) => {
        console.error(e)
    })

    const trendingCollections: Props['ssr']['trendingCollections'] =
        promises?.[0].status === 'fulfilled' && promises[0].value.data
            ? (promises[0].value.data as Props['ssr']['trendingCollections'])
            : {}
    const featuredCollections: Props['ssr']['featuredCollections'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['featuredCollections'])
            : {}

    const trendingMints: Props['ssr']['trendingMints'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['trendingMints'])
            : {}

    res.setHeader(
        'Cache-Control',
        'public, s-maxage=120, stale-while-revalidate=180'
    )
    return {
        props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
    }
}
export default NFTBanner